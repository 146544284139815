<template>
  <div class="flex cursor-pointer items-center">
    <span class="inline-block mr-1">
      <slot name="fileIcon">
        *
      </slot>
    </span>
    <span
      class="inline-block"
      :class="isNodeSelected ? selectedNodeClass : ''"
      @click="handleClick"
    >
      {{ nodeData[nameProperty] }}
    </span>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
    name: 'File',

    props: {
        nodeData: {
            type: Object,
            required: true,
            description: 'data of single node in the directory tree'
        },
        nameProperty: {
            type: String,
            default: 'styleGuideName',
            description: 'Property to be used for name of file(default is name)'
        },
        selectedNode: {
            type: Object,
            default: () => {},
            description: 'data of selected node from tree'
        },
        selectedNodeClass: {
            type: [Object, String, Array],
            default: '',
            description: 'applies specified classes on selected node'
        }
    },

    emits: [
        'nodeSelected'
    ],

    setup (props, { emit }) {
        const handleClick = () => {
            emit('nodeSelected', props.nodeData);
        };
        const isNodeSelected = computed(() => props.selectedNode && props.selectedNode.id && props.nodeData.id ? props.selectedNode.id === props.nodeData.id : false);

        return {
            handleClick,
            isNodeSelected
        };
    }
};
</script>
